<template>
  <div class="mb-20">
    <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
      <div class="flex flex-wrap items-center col-span-1">
        <h2 class="mr-3 text-2xl font-bold">Category Management</h2>
      </div>
    </div>

    <div class="mb-3">
      <button
        type="button"
        class="btn btn-blue btn-sm"
        @click.prevent="$refs.createNewCategory.open()"
      >
        Add new Category
      </button>
    </div>
    <div class="border border-blue-200 border-solid">
      <div class="flex">
        <div class="duration-300 w-full">
          <datatable
            :url="url"
            :ajax="true"
            :ajaxPagination="true"
            :index="true"
            :on-click="click"
            :reverse-index="true"
            :data="categories.data"
            dropdown="actions"
            :pageDetails="true"
            :actions="actions"
            :columns="columns"
            :query="searchQuery"
            :loading="categories.loading"
            ref="table"
          />
        </div>
      </div>
    </div>
    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="createNewCategory"
    >
      <h3 class="font-bold mb-5 text-xl">Add New Category</h3>
      <div>
        <template v-if="getFormError(newCategoryForm)">
          <div class="alert alert-red mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(newCategoryForm) }}</span>
          </div>
        </template>
      </div>
      <form @submit.prevent="submitNewCategory" class="flex flex-col flex-grow">
        <form-group
          v-model="newCategoryForm.data.name.value"
          :form="newCategoryForm"
          name="name"
          type="textarea"
        >
          Category Name
        </form-group>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.createNewCategory.close()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="newCategoryForm.loading"
          >
            <sm-loader-white v-if="newCategoryForm.loading" />
            <span v-else>Add new Category</span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: `${this.$baseurl}/push-notifications/categories`,
      selected: null,
      columns: [
        {
          name: 'name',
          th: 'Name',
        },
        {
          name: 'users_count',
          th: 'Total Users Added',
        },
        {
          name: 'created_at',
          th: 'Date At',
          render: (date, created_at) =>
            this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia'),
        },
      ],
      actions: [
        {
          text: 'View Users',
          class: 'btn btn-green btn-sm',
          action: this.viewCategoryUsers,
        },
        {
          text: 'Delete',
          class: 'btn btn-red btn-sm',
          action: this.delete,
        },
      ],
      newCategoryForm: this.$options.basicForm([
        { name: 'name', rules: 'required' },
      ]),
      categories: this.$options.resource([]),
      loading: null,
      searchQuery: '',
    };
  },

  methods: {
    click(category) {
      this.selected = category;
    },
    async delete(category) {
      await this.sendRequest(
        'admin.pushNotifications.deleteCategory',
        category.id,
        {
          success: () => {
            this.reloadTable();
            this.$success({
              title: 'Operation Successful',
              body: 'Category has been deleted successfully',
              button: 'Okay',
            });
          },
          error: (error) => {
            this.$error({
              title: 'Error Deleting Category',
              body: `Failed to delete category due to: ${
                error.response?.data?.message || 'an unknown error'
              }`,
              button: 'Okay',
            });
          },
        }
      );
    },

    async submitNewCategory() {
      if (!this.validateForm(this.newCategoryForm)) return;
      this.newCategoryForm.loading = true;

      await this.sendRequest('admin.pushNotifications.addNewCategory', {
        data: {
          ...this.getFormData(this.newCategoryForm),
        },
        success: (response) => {
          this.$success({
            title: 'New Category Created',
            body: `Successfully created a new category: ${response.data.data.name}`,
            button: 'Okay',
          });
          this.newCategoryForm = this.resetForm(this.newCategoryForm);
          this.reloadTable();
          this.$emit('success');
          this.$refs.createNewCategory.close();
        },
        error: (error) => {
          this.newCategoryForm.error = error;
          this.mapFormErrors(
            this.newCategoryForm,
            error.response?.data?.errors
          );
        },
      });
      this.newCategoryForm.loading = false;
    },
    viewCategoryUsers(category) {
      return this.$router.push({
        name: 'push-notifications/category',
        params: { id: category.id },
      });
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
  },
};
</script>
